
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// Orbit controls（轨道控制器）可以使得相机围绕目标进行轨道运动。

export default (
  scene: any,
  renderer:any,
  camera:any,
  coll: object,
) => {
var controls2 = new OrbitControls(camera, renderer.domElement);
  function animate2() {
    requestAnimationFrame(animate2);
    controls2.update();
    renderer.render(scene, camera);
  }
  
};
